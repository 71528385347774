import React from 'react'
import { StyledContentContainer, StyledTitle, StyledFeaturedImage, StyledSubTitle, StyledPointer, StyledButton, StyledSubContentContainer, StyledTopAndBottomContentContainer } from './styles'

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

export default function LeadConversionCta({ 
    module,
}) {
    module = module.leadConversionCta;
    if(module) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'lg'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            isOverflowHidden={module.enablePointer}
            innerSpacing={module.enablePointer}
            data-name="lead-conversion-cta"
        >
            <StyledContainer>
                <StyledContentContainer
                    minHeight={module.featuredImage ? '250px' : null}
                >            
                    <StyledTopAndBottomContentContainer 
                        $hasFeaturedImage={module.featuredImage}
                        $hasSubTitle={module.text}
                    >        
                        {module.title && 
                            <StyledTitle
                                titleFontOptions={{ mobile: 'md', desktop: 'sm' }}
                            > 
                                {module.title}
                            </StyledTitle>
                        }
                        <StyledSubContentContainer 
                            isArrowEnabled={module.enablePointer}
                            $hasSubTitle={module.text}
                            $hasFeaturedImage={module.featuredImage}
                        >

                            {module.featuredImage && 
                                <StyledFeaturedImage 
                                    imageFile={module.featuredImage.imageFile}
                                    alt={module.featuredImage.altText}
                                    $hasSubTitle={module.text}
                                />
                            }
                            {module.text && 
                                <StyledSubTitle 
                                    $hasFeaturedImage={module.featuredImage}
                                    bodyFontOptions={{ mobile: 'lg', desktop: 'xl' }}
                                >
                                    {module.text}
                                </StyledSubTitle>
                            }
                            {module.enablePointer && 
                                <StyledPointer 
                                    color={module.pointerColor} 
                                    $hasSubTitle={module.text}
                                    $hasFeaturedImage={module.featuredImage}
                                />
                            }

                        </StyledSubContentContainer>
                    </StyledTopAndBottomContentContainer>
                    {module.buttonLink && 
                        <StyledTopAndBottomContentContainer 
                            $hasFeaturedImage={module.featuredImage}
                            $hasSubTitle={module.text}
                        >
                            <StyledButton 
                                href={module.buttonLink.url} 
                                buttonOptions={module.buttonOptions}
                                fontSize="md"    
                            >
                                {module.buttonLink.title}
                            </StyledButton>
                        </StyledTopAndBottomContentContainer>
                    }
                
                </StyledContentContainer>
            </StyledContainer>
        </StyledModuleContainer>
    )
    return null;
}
