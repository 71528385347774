import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrPointer from '@commons/wr-pointer';
import WrButton from '@commons/wr-button';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '@commons/wysiwyg-content';

const mobileContentSpacing = `45px`;


const md_image_offset_padding = `280px`; 
const md_arrow_offset_padding = `80px`;
const md_content_max_width = `1060px`; // 600px plus offsets

export const StyledContentContainer = styled.div`

    ${theme.mediaQuerys.mdUp} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        min-height: ${props => props.minHeight || ''};
    }
`

export const StyledTopAndBottomContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    ${theme.mediaQuerys.mdUp} {
        width: 100%;
        position: static;
        margin-left: auto;
        max-width: ${md_content_max_width};
        padding-left: ${md_arrow_offset_padding}; // for arrow offset
        padding-right: ${md_arrow_offset_padding}; // for arrow offset
    }

    ${props => props.$hasFeaturedImage && css`
    
        ${theme.mediaQuerys.mdUp} {
            padding-left: ${md_image_offset_padding}; // for absolute image offset         
        }
    `}

    ${props => props.$hasSubTitle && css`

        ${theme.mediaQuerys.mdUp} {
            max-width: 100%;
            padding: 0; // for absolute image offset         
        }
    `}
`

export const StyledSubContentContainer = styled.div`
    position: relative;
    max-width: 100%;
   

    ${theme.mediaQuerys.mdUp} {
        width: auto;
        position: static;
    }

    ${props => props.isArrowEnabled && css`
        width: 310px;

        ${theme.mediaQuerys.smUp} {
            width: 430px;
        }
    `}

    ${props => props.$hasSubTitle && css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 350px;
        /* width: 400px; */

        ${theme.mediaQuerys.smUp} {
            width: 480px;
        }

        ${theme.mediaQuerys.mdUp} {
            position: relative;
            flex-direction: row;  
            width: 620px;
        }
    `}

    ${props => !props.$hasFeaturedImage && css`
        width: 100%; 

        ${theme.mediaQuerys.smUp} {
            width: 90%;
        }

        ${theme.mediaQuerys.mdUp} {
            width: 90%;
        }
    `}
`

// client requested no headings on this module
export const StyledTitle = styled(ModuleTitle)`
    margin: 0 0 35px 0;
    font-weight: 800;
    text-align: center;
`

export const StyledSubTitle = styled(WysiwygContent)`
    margin: 0 0 ${mobileContentSpacing} 0;
    padding: 0 20px;
    text-align: center;

    ${theme.mediaQuerys.xsUp} {
        padding: 0 30px;
    }

    ${props => props.$hasFeaturedImage && css`
        
        ${theme.mediaQuerys.mdUp} {
            text-align: left;
        }
    `}
`

export const StyledFeaturedImage = styled(WrImage)`
    margin-bottom: 40px;
    width: 160px;
    box-sizing: content-box;

    ${theme.mediaQuerys.smUp} {
        min-width: 240px;
        width: 240px;
    }

    ${props => !props.$hasSubTitle && css`
    
        ${theme.mediaQuerys.mdUp} {
            position: absolute !important; // overrides gatsby-image
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            /* padding-right: 200px; */
        }
    `}
`

// TODO => maybe make these styles reusable?
// this arrow is used everywhere in a similar wild css format??
export const StyledPointer = styled(WrPointer)`
    position: absolute;
    right: 0;
    bottom: 40px;
    height: 165px;
    transform: rotate(-25deg);

    ${theme.mediaQuerys.smUp} {
        height: 235px;
        bottom: 45px;
    }

    ${theme.mediaQuerys.mdUp} {
        height: 140px;
        bottom: 80px;
        transform: rotate(-15deg);
    }

    ${theme.mediaQuerys.lgUp} {
        height: 150px;
        bottom: 70px;
        transform: rotate(-15deg);
    }

    /* CTA w/ sub title and featured image */
    ${props => props.$hasSubTitle && props.$hasFeaturedImage ? css`
        right: -30px;
        bottom: 25px;
        height: 150px;
        transform: rotate(-35deg);

        ${theme.mediaQuerys.xsUp} {
            right: -40px;
        }

        ${theme.mediaQuerys.smUp} {
            height: 180px;
            bottom: 45px;
            right: -75px;
            transform: rotate(-45deg);
        }

        ${theme.mediaQuerys.mdUp} {
            height: 140px;
            bottom: 35px;
            right: -50px;
            transform: rotate(-35deg);
        }
    `: null}

    /* CTA w/ only title */
    ${props => !props.$hasSubTitle && !props.$hasFeaturedImage ? css`
        right: -50px;
        bottom: 10px;
        height: 100px;
        transform: rotate(-15deg);

        ${theme.mediaQuerys.smUp} {
            right: -110px;
            bottom: 25px;
            height: 150px;
        }

        ${theme.mediaQuerys.mdUp} {
            right: 0;
            bottom: 80px
        }

        ${theme.mediaQuerys.lgUp} {
            height: 150px;
            bottom: 70px;
        }

    `: null}

    /* CTA w/ title & subtitle */
    ${props => props.$hasSubTitle && !props.$hasFeaturedImage ? css`
        right: -50px;
        bottom: 10px;
        height: 100px;
        transform: rotate(-15deg);

        ${theme.mediaQuerys.smUp} {
            right: -110px;
            bottom: 25px;
            height: 150px;
        }

    `: null}
`

export const StyledButton = styled(WrButton)`

    ${theme.mediaQuerys.mdUp} {
        max-width: 95%;
        margin: 0 auto;
    }

    ${theme.mediaQuerys.lgUp} {
        max-width: calc(100% - 100px);
    }
`